import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [0,13,5,4,9,10,15,16,6,2];

export const dictionary = {
		"/(marketing)": [~18,[3]],
		"/(marketing)/app": [~22,[3]],
		"/blog": [~36,[13]],
		"/blog/[slug]": [~37,[13]],
		"/(marketing)/contact": [~23,[3]],
		"/(marketing)/faq/[[slug]]": [~24,[3]],
		"/(marketing)/food-recycler": [~25,[3,7]],
		"/(marketing)/(referral)/friends": [~20,[3,5]],
		"/(marketing)/(referral)/friend": [~19,[3,4]],
		"/(marketing)/impact": [~26,[3]],
		"/(marketing)/lab/[...slug]": [~27,[3,8]],
		"/(marketing)/legal/open-source-software-disclosure": [29,[3,9]],
		"/(marketing)/legal/[[slug]]": [28,[3,9,10]],
		"/(marketing)/licenses": [30,[3]],
		"/lp/[slug]": [~38,[14]],
		"/news": [~39,[15]],
		"/news/[slug]": [~40,[15]],
		"/(marketing)/order-now": [~31,[3,11]],
		"/(marketing)/order-now/[orderPage]": [~32,[3,11]],
		"/order/success": [41,[16]],
		"/(marketing)/(referral)/refer": [21,[3,6]],
		"/(marketing)/reviews": [~33,[3,12]],
		"/(marketing)/system": [~34,[3]],
		"/(marketing)/team": [~35,[3]],
		"/(landingpage)/[slug]": [17,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';