import log from '$lib/utils/log';
import { analytics } from '$lib/utils/segment';
import type { HandleClientError } from '@sveltejs/kit';

export const handleError: HandleClientError = async ({ error }) => {
	console.log('handleError', error);

	try {
		const { anonymousId } = await analytics.user();

		log.anonymousId = anonymousId() || '';
	} catch (e) {
		log.scope('Hooks - Client').exception(e);
	}

	log.scope('Hooks - Client').exception(error);
};
